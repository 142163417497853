import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row q-col-gutter-md items-center q-mb-md" }
const _hoisted_2 = { class: "col-xs-12 col-md-4" }
const _hoisted_3 = { class: "col-xs-12 col-md-4" }
const _hoisted_4 = { class: "col-xs-12 col-md-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_errors = _resolveComponent("errors")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_market_selector = _resolveComponent("market-selector")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_errors, { errors: _ctx.errors }, null, 8, ["errors"]),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_q_input, {
          modelValue: _ctx.accessRequestModel.firstname,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.accessRequestModel.firstname = $event)),
          label: _ctx.t('First name')
        }, null, 8, ["modelValue", "label"])
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_q_input, {
          modelValue: _ctx.accessRequestModel.middlename,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.accessRequestModel.middlename = $event)),
          label: _ctx.t('Middle name')
        }, null, 8, ["modelValue", "label"])
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_q_input, {
          modelValue: _ctx.accessRequestModel.lastname,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.accessRequestModel.lastname = $event)),
          label: _ctx.t('Last name')
        }, null, 8, ["modelValue", "label"])
      ])
    ]),
    _createVNode(_component_q_input, {
      class: "q-mb-md",
      modelValue: _ctx.accessRequestModel.email,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.accessRequestModel.email = $event)),
      label: _ctx.t('Email')
    }, null, 8, ["modelValue", "label"]),
    _createVNode(_component_q_select, {
      class: "q-mb-md",
      options: _ctx.userRoleOptions,
      "option-value": "value",
      "option-label": "label",
      modelValue: _ctx.accessRequestModel.role,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.accessRequestModel.role = $event)),
      label: _ctx.t('Role'),
      "emit-value": "",
      "map-options": "",
      "hide-bottom-space": ""
    }, null, 8, ["options", "modelValue", "label"]),
    _createVNode(_component_market_selector, {
      modelValue: _ctx.accessRequestModel.market,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.accessRequestModel.market = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_q_btn, {
      onClick: _ctx.requestAccess,
      label: _ctx.t('Request access'),
      class: "full-width q-my-md",
      color: "primary",
      loading: _ctx.isLoading
    }, null, 8, ["onClick", "label", "loading"]),
    _createVNode(_component_q_btn, {
      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.emit('cancelRequest'))),
      label: _ctx.t('Cancel'),
      class: "full-width",
      flat: "",
      color: "grey"
    }, null, 8, ["label"])
  ], 64))
}
import { ApiAuthenticationError } from '@/api/core/api';
import User from '@/api/models/User';
import { MutationTypes } from '@/store/mutations';
import { State } from '@/store/state';
import { I18n, LocaleMessages, VueMessageType } from 'vue-i18n';
import { Router } from 'vue-router';
import { Store } from 'vuex';

const load = (
    router: Router,
    store: Store<State>,
    i18n: I18n<LocaleMessages<VueMessageType>, unknown, unknown, false>
): void => {
    router.beforeEach(async (to, _, next) => {
        // non-protected routes can just move on.
        if (to.meta.auth !== true) return next();

        // move on if user is authenticated already
        if (store.getters.isAuthenticated) return next();

        // check if user is authenticated on backend
        try {
            store.commit(MutationTypes.setIsAuthenticating, true);
            const user = await User.user();
            store.commit(MutationTypes.setUser, user);
            i18n.global.locale.value = user.language || i18n.global.locale.value;
            next();
        } catch (e) {
            if (e instanceof ApiAuthenticationError) {
                next({ name: 'sign-in' });
            }

            throw e;
        } finally {
            store.commit(MutationTypes.setIsAuthenticating, false);
        }
    });
};

export default {
    load,
};

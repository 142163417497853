
import { ApiValidationError } from '@/api/core/api';
import User, { PasswordData } from '@/api/models/User';
import { computed, ComputedRef, defineComponent, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Errors from '@/components/Notifications/Errors.vue';
import Success from '@/components/Notifications/Success.vue';
import { useStore } from 'vuex';

export default defineComponent({
    components: { Errors, Success },
    setup() {
        const { t } = useI18n();

        const store = useStore();
        const userModel: ComputedRef<User> = computed(() => store.getters.user);

        const passwordErrors: Ref<string[]> = ref([]);
        const passwordSuccess: Ref<boolean> = ref(false);
        const isSavingPassword: Ref<boolean> = ref(false);
        const passwordModel: Ref<PasswordData> = ref({
            current_password: '',
            new_password: '',
            new_password_confirmation: '',
        });

        const savePassword = async () => {
            passwordErrors.value = [];
            passwordSuccess.value = false;
            isSavingPassword.value = true;
            try {
                await userModel.value.savePassword(passwordModel.value);
                passwordSuccess.value = true;
                passwordModel.value = {
                    current_password: '',
                    new_password: '',
                    new_password_confirmation: '',
                };
            } catch (e) {
                if (e instanceof ApiValidationError) {
                    passwordErrors.value = e.getErrors();
                    return;
                }
                throw e;
            } finally {
                isSavingPassword.value = false;
            }
        };

        return {
            t,
            userModel,
            passwordModel,
            savePassword,
            passwordErrors,
            isSavingPassword,
            passwordSuccess,
        };
    },
});

import api from '@/api/core/api';

export interface MarketData {
    uuid?: string;
    country?: string;
    region?: string;
}

export default class Market implements MarketData {
    uuid?: string;
    country?: string;
    region?: string;

    constructor(data: MarketData) {
        this.uuid = data.uuid;
        this.country = data.country;
        this.region = data.region;
    }

    static async all(): Promise<Market[]> {
        const response = await api.get<MarketData[]>('/markets');
        return response.data.map((m) => new Market(m));
    }
}

import User from '@/api/models/User';
import { MutationTree } from 'vuex';
import { State } from './state';

export enum MutationTypes {
    setUser = 'setUser',
    setIsAuthenticating = 'setIsAuthenticating',
}

interface Mutations extends MutationTree<State> {
    [MutationTypes.setUser]: (state: State, payload: User) => void;
    [MutationTypes.setIsAuthenticating]: (state: State, payload: boolean) => void;
}

const mutations: Mutations = {
    [MutationTypes.setUser]: (state, payload) => {
        state.user = payload;
    },

    [MutationTypes.setIsAuthenticating]: (state, payload) => {
        state.isAuthenticating = payload;
    },
};

export default mutations;

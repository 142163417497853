import api, { PaginatedApiMeta } from '@/api/core/api';
import Market, { MarketData } from './Market';
import { UserData, UserLanguage, UserRole } from './User';

export interface AccessRequestData {
    uuid?: string;
    firstname?: string;
    middlename?: string;
    lastname?: string;
    email?: string;
    market?: MarketData;
    role?: UserRole;
    language?: UserLanguage;
    accepted?: boolean;
    accepted_by?: UserData;
    accepted_at?: string;
    created_at?: string;
}

export default class AccessRequest implements AccessRequestData {
    uuid?: string;
    firstname?: string;
    middlename?: string;
    lastname?: string;
    email?: string;
    market?: Market;
    role?: UserRole;
    language?: UserLanguage;
    accepted?: boolean;
    accepted_by?: UserData;
    accepted_at?: string;
    created_at?: string;

    constructor(data: AccessRequestData) {
        this.uuid = data.uuid;
        this.firstname = data.firstname;
        this.middlename = data.middlename;
        this.lastname = data.lastname;
        this.email = data.email;
        this.market = data.market;
        this.role = data.role;
        this.language = data.language || UserLanguage.EN;
        this.accepted = data.accepted || false;
        this.accepted_by = data.accepted_by;
        this.accepted_at = data.accepted_at;
        this.created_at = data.created_at;
    }

    getFullName(): string {
        return `${this.firstname || ''} ${this.middlename || ''} ${this.lastname || ''}`.replace(
            '  ',
            ' '
        );
    }

    static async all(
        config: {
            includeAcceptedAccessRequests?: boolean;
            perPage?: number;
            page?: number;
        } = {}
    ): Promise<{ accessRequests: AccessRequest[]; meta: PaginatedApiMeta }> {
        const response = await api.getPaginated<AccessRequest[]>('/access-requests', {
            show_accepted: config.includeAcceptedAccessRequests,
            number: config.perPage,
            page: config.page,
        });

        return {
            accessRequests: response.data.map(
                (request: AccessRequest) => new AccessRequest(request)
            ),
            meta: response.meta,
        };
    }

    async request(): Promise<void> {
        await api.post<void>('/access-requests', {
            firstname: this.firstname,
            middlename: this.middlename,
            lastname: this.lastname,
            email: this.email,
            market_uuid: this.market?.uuid,
            language: this.language || UserLanguage.EN,
            role: this.role,
        });
    }

    async accept(): Promise<void> {
        await api.patch<void>(`/access-requests/${this.uuid}/accept`);
    }
}

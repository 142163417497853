import { State } from '@/store/state';
import { Router } from 'vue-router';
import { Store } from 'vuex';

const load = (router: Router, store: Store<State>): void => {
    router.beforeEach(async (to, _, next) => {
        // non-protected routes can just move on.
        if (to.meta.guest !== true) return next();

        // move on if user is authenticated already
        if (store.getters.isAuthenticated) return next({ name: 'request.overview' });

        next();
    });
};

export default {
    load,
};

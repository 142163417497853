
import { formatDate } from '@/util/formatters';
import { QPaginationMeta } from '@/util/pagination';
import AccessRequest from '@/api/models/AccessRequest';
import { defineComponent, onBeforeMount, Ref, ref, computed, watch } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import ConfirmDialog from '@/components/Interaction/ConfirmDialog.vue';
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
    name: 'AccessRequestsOverview',
    components: { ConfirmDialog },
    setup() {
        const { t } = useI18n();

        const accessRequests: Ref<AccessRequest[]> = ref([]);
        const accessRequestAccepting: Ref<AccessRequest> = ref(new AccessRequest({}));
        const showConfirmDialog: Ref<boolean> = ref(false);
        const isProcessingAcceptment: Ref<boolean> = ref(false);

        const columns = [
            {
                label: t('Name'),
                field: (request: AccessRequest) => request.getFullName(),
                name: 'name',
                align: 'left',
                sortable: false,
            },
            { label: t('Email'), field: 'email', name: 'email', align: 'left', sortable: false },
            { label: t('Market'), field: 'market', name: 'market', align: 'left', sortable: false },
            {
                label: t('Requested role'),
                field: (request: AccessRequest) => t(request.role || ''),
                name: 'role',
                align: 'left',
                sortable: false,
            },
            {
                label: t('Date requested'),
                field: (request: AccessRequest) => formatDate(request.created_at || ''),
                name: 'created_at',
                align: 'left',
                sortable: false,
            },
            {
                label: '',
                field: 'accepted',
                name: 'accepted',
                align: 'right',
                sortable: false,
            },
        ];

        const loadingAccessRequests: Ref<boolean> = ref(true);

        const pagination: Ref<QPaginationMeta> = ref({
            sortBy: 'lastname',
            descending: true,
            page: 1,
            rowsPerPage: 10,
            rowsNumber: 0,
        });

        const loadAccessRequests = async (props: { pagination: QPaginationMeta }) => {
            loadingAccessRequests.value = true;

            const paginatedAccessRequests = await AccessRequest.all({
                includeAcceptedAccessRequests: showAcceptedAccessRequests.value,
                perPage: props.pagination.rowsPerPage,
                page: props.pagination.page,
            });

            accessRequests.value = paginatedAccessRequests.accessRequests;
            pagination.value.rowsNumber = paginatedAccessRequests.meta.total;
            pagination.value.page = paginatedAccessRequests.meta.current_page;
            pagination.value.rowsPerPage = paginatedAccessRequests.meta.per_page;

            loadingAccessRequests.value = false;
        };

        const openConfirmDialog = (request: AccessRequest) => {
            accessRequestAccepting.value = request;
            showConfirmDialog.value = true;
        };

        const closeDialog = () => {
            showConfirmDialog.value = false;
        };

        const acceptRequest = async () => {
            isProcessingAcceptment.value = true;
            await accessRequestAccepting.value.accept();
            await loadAccessRequests({ pagination: pagination.value });
            showConfirmDialog.value = false;
            isProcessingAcceptment.value = false;
            userCreatedNotification();
        };

        const $q = useQuasar();
        const userCreatedNotification = () => {
            $q.notify({
                color: 'positive',
                message:
                    t('The account is created successfully.') +
                    ' ' +
                    accessRequestAccepting.value.getFullName() +
                    ' ' +
                    t('is notified') +
                    '.',
                icon: 'check',
                position: 'top',
                progress: true,
                group: false,
                timeout: 5000,
                actions: [
                    {
                        label: t('Hide'),
                        color: 'white',
                        handler: () => {
                            /* ... */
                        },
                    },
                ],
            });
        };

        onBeforeMount(async () => await loadAccessRequests({ pagination: pagination.value }));

        const showAcceptedAccessRequests: Ref<boolean> = ref(false);
        watch([showAcceptedAccessRequests], () => {
            loadAccessRequests({ pagination: { ...pagination.value, page: 1 } });
        });

        return {
            t,
            pagination,
            accessRequestAccepting,
            showConfirmDialog,
            accessRequests,
            showAcceptedAccessRequests,
            columns,
            loadAccessRequests,
            openConfirmDialog,
            formatDate,
            closeDialog,
            acceptRequest,
            isProcessingAcceptment,
            loadingAccessRequests,
            pagesNumber: computed(() =>
                Math.ceil(pagination.value.rowsNumber / pagination.value.rowsPerPage)
            ),
        };
    },
});

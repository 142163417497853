import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row q-col-gutter-md" }
const _hoisted_2 = { class: "col-md-6 col-xs-12" }
const _hoisted_3 = { class: "text-h6" }
const _hoisted_4 = { class: "col-md-6 col-xs-12" }
const _hoisted_5 = { class: "text-h6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_errors = _resolveComponent("errors")!
  const _component_success = _resolveComponent("success")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_spinner_gears = _resolveComponent("q-spinner-gears")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_q_card, { "full-height": "" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode("div", _hoisted_3, _toDisplayString(_ctx.t('My account')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_input, {
                modelValue: _ctx.userModel.firstname,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.userModel.firstname = $event)),
                label: _ctx.t('Firstname'),
                class: "q-mb-md",
                readonly: ""
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_q_input, {
                modelValue: _ctx.userModel.middlename,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.userModel.middlename = $event)),
                label: _ctx.t('Middlename'),
                class: "q-mb-md",
                readonly: ""
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_q_input, {
                modelValue: _ctx.userModel.lastname,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.userModel.lastname = $event)),
                label: _ctx.t('Lastname'),
                class: "q-mb-md",
                readonly: ""
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_q_input, {
                modelValue: _ctx.userModel.email,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.userModel.email = $event)),
                label: _ctx.t('Email'),
                class: "q-mb-md",
                readonly: ""
              }, null, 8, ["modelValue", "label"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode(_component_q_card, { style: {"height":"100%"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode("div", _hoisted_5, _toDisplayString(_ctx.t('My password')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_errors, { errors: _ctx.passwordErrors }, null, 8, ["errors"]),
          (_ctx.passwordSuccess)
            ? (_openBlock(), _createBlock(_component_success, { key: 0 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Your password is updated successfully')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_input, {
                modelValue: _ctx.passwordModel.current_password,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.passwordModel.current_password = $event)),
                type: "password",
                label: _ctx.t('Current password'),
                class: "q-mb-md"
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_q_input, {
                modelValue: _ctx.passwordModel.new_password,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.passwordModel.new_password = $event)),
                type: "password",
                label: _ctx.t('Password'),
                class: "q-mb-md"
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_q_input, {
                modelValue: _ctx.passwordModel.new_password_confirmation,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.passwordModel.new_password_confirmation = $event)),
                type: "password",
                label: _ctx.t('Password confirmation')
              }, null, 8, ["modelValue", "label"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, {
            class: "q-px-md",
            align: "right"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                label: _ctx.t('Save'),
                color: "primary",
                onClick: _ctx.savePassword
              }, null, 8, ["label", "onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_inner_loading, { showing: _ctx.isSavingPassword }, {
            default: _withCtx(() => [
              _createVNode(_component_q_spinner_gears, {
                size: "50px",
                color: "primary"
              })
            ]),
            _: 1
          }, 8, ["showing"])
        ]),
        _: 1
      })
    ])
  ]))
}
import { State } from '@/store/state';
import { Router } from 'vue-router';
import { Store } from 'vuex';

const load = (router: Router, store: Store<State>): void => {
    router.beforeEach(async (to, _, next) => {
        if (to.meta.moderator !== true) return next();

        if (store.getters.isModerator || store.getters.isAdmin) {
            next();
        } else {
            next({ name: 'sign-in' });
        }
    });
};

export default {
    load,
};

<template>
    <q-layout view="hhh lpR lff">
        <q-header v-if="isAuthenticated" elevated class="bg-primary text-white">
            <q-toolbar>
                <q-btn dense flat round icon="menu" @click="toggleLeftDrawer" />
                <img style="height: 45px; margin: 8px" src="/img/logo.png" />
                <q-space />
                <q-btn
                    :to="{ name: 'my-account' }"
                    class="text-weight-medium"
                    flat
                    style="color: #fff; text-decoration: none"
                >
                    {{ user.getFullName() }}
                </q-btn>
                <q-btn class="q-ml-md" round flat icon="logout" @click="signOut" />
            </q-toolbar>
        </q-header>

        <q-drawer
            class="bg-primary text-white"
            :width="200"
            :breakpoint="700"
            show-if-above
            v-model="drawerOpen"
            v-if="isAuthenticated"
            side="left"
        >
            <q-scroll-area class="fit">
                <q-list class="menu-list">
                    <q-item
                        clickable
                        active-class="bg-secondary"
                        v-ripple
                        :to="{ name: 'request.overview' }"
                    >
                        <q-item-section avatar>
                            <q-icon name="assignment" />
                        </q-item-section>

                        <q-item-section class="text-weight-medium">
                            {{ t('Requests') }}
                        </q-item-section>
                    </q-item>

                    <q-item
                        clickable
                        active-class="bg-secondary"
                        v-ripple
                        v-if="isAdmin || isModerator"
                        :to="{ name: 'users.overview' }"
                    >
                        <q-item-section avatar>
                            <q-icon name="people" />
                        </q-item-section>

                        <q-item-section class="text-weight-medium">
                            {{ t('Users') }}
                        </q-item-section>
                    </q-item>

                    <q-item
                        clickable
                        active-class="bg-secondary"
                        v-ripple
                        v-if="isAdmin"
                        :to="{ name: 'access-requests.overview' }"
                    >
                        <q-item-section avatar>
                            <q-icon name="lock" />
                        </q-item-section>

                        <q-item-section class="text-weight-medium">
                            {{ t('Access requests') }}
                        </q-item-section>
                    </q-item>
                </q-list>
            </q-scroll-area>
        </q-drawer>

        <q-page-container>
            <q-page v-if="isAuthenticated" padding>
                <router-view />
            </q-page>
            <q-page v-else>
                <router-view />
            </q-page>
        </q-page-container>
    </q-layout>
</template>

<script>
import User from '@/api/models/User';
import { MutationTypes } from '@/store/mutations';
import { ref, defineComponent, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'App',

    setup() {
        const router = useRouter();
        const store = useStore();
        const { t } = useI18n();
        const drawerOpen = ref(false);

        const user = computed(() => store.getters.user);

        const isAuthenticated = computed(() => store.getters.isAuthenticated);
        const isAuthenticating = computed(() => store.getters.isAuthenticating);

        const signOut = async () => {
            User.signOut();
            store.commit(MutationTypes.setUser, null);
            router.replace({ name: 'sign-in' });
        };

        const isAdmin = computed(() => store.getters.isAdmin);
        const isModerator = computed(() => store.getters.isModerator);

        return {
            t,
            user,
            isAdmin,
            isModerator,
            drawerOpen,
            toggleLeftDrawer() {
                drawerOpen.value = !drawerOpen.value;
            },
            signOut,
            isAuthenticated,
            isAuthenticating,
        };
    },
});
</script>

<style>
body {
    background-color: rgb(243, 243, 243);
}
</style>


import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import Errors from '../Notifications/Errors.vue';

export default defineComponent({
    components: { Errors },
    name: 'CrudDialog',
    emits: ['save', 'delete'],
    setup() {
        const { t } = useI18n();

        return { t };
    },
    props: {
        /**
         * Displayed at the top of the dialog.
         */
        title: {
            type: String,
            required: true,
        },
        /**
         * Validation errors as array of strings.
         */
        errors: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        /**
         * Whether the delete button should be visible.
         */
        withDelete: {
            type: Boolean,
            default: false,
        },
        /**
         * Whether the save button should be visible.
         */
        withSave: {
            type: Boolean,
            default: false,
        },
        /**
         * Whether an async action is currently running.
         */
        isProcessing: {
            type: Boolean,
            default: false,
        },
    },
});

import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row q-col-gutter-md"
}
const _hoisted_2 = { class: "col-xs-12" }
const _hoisted_3 = { class: "q-table__title q-mb-sm" }
const _hoisted_4 = { class: "row q-col-gutter-lg" }
const _hoisted_5 = { class: "col-xs-12 col-sm-6" }
const _hoisted_6 = { class: "col-xs-12 col-sm-6" }
const _hoisted_7 = { class: "col-xs-12" }
const _hoisted_8 = { class: "row q-col-gutter-lg" }
const _hoisted_9 = { class: "col-xs-12 col-md-6" }
const _hoisted_10 = { class: "col-xs-12 col-md-6" }
const _hoisted_11 = {
  key: 0,
  class: "col-xs-12"
}
const _hoisted_12 = { class: "col-xs-12 col-md-6" }
const _hoisted_13 = { class: "col-xs-12 col-md-6" }
const _hoisted_14 = { class: "col-xs-12" }
const _hoisted_15 = { class: "row q-col-gutter-lg" }
const _hoisted_16 = { class: "col-xs-12" }
const _hoisted_17 = { class: "text-caption q-mb-sm" }
const _hoisted_18 = { class: "col-xs-12" }
const _hoisted_19 = { class: "col-xs-12" }
const _hoisted_20 = { class: "flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_brand_selector = _resolveComponent("brand-selector")!
  const _component_q_radio = _resolveComponent("q-radio")!
  const _component_market_selector = _resolveComponent("market-selector")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_spinner_gears = _resolveComponent("q-spinner-gears")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.request)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_q_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_card_section, null, {
                  default: _withCtx(() => [
                    _createVNode("div", _hoisted_3, _toDisplayString(_ctx.t('Request')) + " " + _toDisplayString(_ctx.request.id), 1),
                    _createVNode("div", _hoisted_4, [
                      _createVNode("div", _hoisted_5, [
                        _createVNode(_component_q_input, {
                          label: _ctx.t('Applicant'),
                          "model-value": _ctx.request.user.getFullName(),
                          readonly: ""
                        }, {
                          prepend: _withCtx(() => [
                            _createVNode(_component_q_icon, { name: "person" })
                          ]),
                          _: 1
                        }, 8, ["label", "model-value"])
                      ]),
                      _createVNode("div", _hoisted_6, [
                        _createVNode(_component_q_input, {
                          label: _ctx.t('Submitted at'),
                          "model-value": _ctx.dateFormatter(_ctx.request.created_at),
                          readonly: ""
                        }, {
                          prepend: _withCtx(() => [
                            _createVNode(_component_q_icon, { name: "event" })
                          ]),
                          _: 1
                        }, 8, ["label", "model-value"])
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createVNode("div", _hoisted_7, [
            _createVNode(_component_q_card, { class: "full-height" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_card_section, { class: "q-pb-lg" }, {
                  default: _withCtx(() => [
                    _createVNode("div", _hoisted_8, [
                      _createVNode("div", _hoisted_9, [
                        _createVNode(_component_q_input, {
                          label: _ctx.t('Product number'),
                          modelValue: _ctx.request.product_number,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.request.product_number = $event)),
                          readonly: ""
                        }, {
                          prepend: _withCtx(() => [
                            _createVNode(_component_q_icon, { name: "tag" })
                          ]),
                          _: 1
                        }, 8, ["label", "modelValue"])
                      ]),
                      _createVNode("div", _hoisted_10, [
                        _createVNode(_component_brand_selector, {
                          modelValue: _ctx.request.brand,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.request.brand = $event)),
                          readonly: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      (_ctx.request.reference_number)
                        ? (_openBlock(), _createBlock("div", _hoisted_11, [
                            _createVNode(_component_q_input, {
                              label: _ctx.t('Reference number'),
                              modelValue: _ctx.request.reference_number,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.request.reference_number = $event)),
                              readonly: ""
                            }, {
                              prepend: _withCtx(() => [
                                _createVNode(_component_q_icon, { name: "tag" })
                              ]),
                              _: 1
                            }, 8, ["label", "modelValue"])
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode("div", _hoisted_12, [
                        _createTextVNode(_toDisplayString(_ctx.t('Is a 360 image')) + ": ", 1),
                        _createVNode(_component_q_radio, {
                          modelValue: _ctx.request.is_360_image,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.request.is_360_image = $event)),
                          val: true,
                          label: _ctx.t('Yes'),
                          disable: ""
                        }, null, 8, ["modelValue", "label"]),
                        _createVNode(_component_q_radio, {
                          modelValue: _ctx.request.is_360_image,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.request.is_360_image = $event)),
                          val: false,
                          label: _ctx.t('No'),
                          disable: ""
                        }, null, 8, ["modelValue", "label"])
                      ]),
                      _createVNode("div", _hoisted_13, [
                        _createVNode(_component_market_selector, {
                          modelValue: _ctx.request.market,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.request.market = $event)),
                          readonly: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _createVNode("div", _hoisted_14, [
                        _createVNode("div", _hoisted_15, [
                          _createVNode("div", _hoisted_16, [
                            _createVNode("div", _hoisted_17, _toDisplayString(_ctx.t('Files')), 1),
                            _createVNode(_component_q_list, null, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.request.files, (file) => {
                                  return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                    clickable: "",
                                    onClick: _withModifiers(($event: any) => (_ctx.downloadDocument(file)), ["prevent"]),
                                    key: file.uuid,
                                    class: "rounded-borders"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item_section, {
                                        avatar: "",
                                        top: ""
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_avatar, {
                                            icon: "attach_file",
                                            color: "primary",
                                            "text-color": "white"
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item_label, { lines: "1" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(file.file_name), 1)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_q_inner_loading, { showing: _ctx.downloading }, null, 8, ["showing"])
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])), [
                                    [_directive_ripple]
                                  ])
                                }), 128))
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _createVNode("div", _hoisted_18, [
                        _createVNode(_component_q_input, {
                          type: "textarea",
                          label: _ctx.t('Comments'),
                          modelValue: _ctx.request.comments,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.request.comments = $event)),
                          readonly: "",
                          autogrow: ""
                        }, {
                          prepend: _withCtx(() => [
                            _createVNode(_component_q_icon, { name: "comments" })
                          ]),
                          _: 1
                        }, 8, ["label", "modelValue"])
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createVNode("div", _hoisted_19, [
            _createVNode("div", _hoisted_20, [
              _createVNode(_component_q_btn, {
                flat: "",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$router.push({ name: 'request.overview' })))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Requests overview')), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_q_inner_loading, { showing: _ctx.loadingRequest }, {
      default: _withCtx(() => [
        _createVNode(_component_q_spinner_gears, {
          size: "50px",
          color: "primary"
        })
      ]),
      _: 1
    }, 8, ["showing"])
  ], 64))
}
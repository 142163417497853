
import Market, { MarketData } from '@/api/models/Market';
import { computed, defineComponent, onMounted, Ref, ref, PropType } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';

interface MarketOption extends MarketData {
    disable: boolean;
}

export default defineComponent({
    name: 'BrandSelector',
    props: {
        modelValue: {
            type: Object as PropType<Market>,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const markets: Ref<Market[]> = ref([]);
        const loadingMarkets: Ref<boolean> = ref(true);

        // shows the countries and the regions as options where the regions are disabled
        const marketOptions = computed(() =>
            markets.value.reduce((acc: MarketOption[], val: Market) => {
                const last = acc.slice(-1)[0];

                if (last == undefined || last.region !== val.region) {
                    return [
                        ...acc,
                        { country: val.region, disable: true },
                        { ...val, disable: false },
                    ];
                }

                return [...acc, { ...val, disable: false }];
            }, [])
        );

        const loadMarkets = async () => {
            loadingMarkets.value = true;
            markets.value = await Market.all();
            loadingMarkets.value = false;
        };

        const marketModel = computed({
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value),
        });

        onMounted(loadMarkets);

        return {
            t,
            loadingMarkets,
            marketOptions,
            marketModel,
        };
    },
});

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row justify-center q-mt-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_pagination = _resolveComponent("q-pagination")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_table, {
            flat: "",
            title: _ctx.t('Requests'),
            rows: _ctx.requests,
            columns: _ctx.columns,
            "no-data-label": _ctx.t('No data was found'),
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
            onRequest: _ctx.loadRequests,
            loading: _ctx.loadingRequests,
            onRowClick: _ctx.showRequest,
            grid: false,
            "binary-state-sort": "",
            "hide-bottom": ""
          }, {
            "top-right": _withCtx(() => [
              (_ctx.isAdmin)
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    onClick: _ctx.exportRequests,
                    class: "q-mr-sm"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Export requests')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_q_btn, {
                color: "primary",
                onClick: _ctx.newRequest
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Create request')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["title", "rows", "columns", "no-data-label", "pagination", "onRequest", "loading", "onRowClick"]),
          _createVNode("div", _hoisted_1, [
            _createVNode(_component_q_pagination, {
              modelValue: _ctx.pagination.page,
              "onUpdate:modelValue": [
                _cache[2] || (_cache[2] = ($event: any) => (_ctx.pagination.page = $event)),
                _cache[3] || (_cache[3] = ($event: any) => (_ctx.loadRequests({ pagination: _ctx.pagination })))
              ],
              color: "primary",
              max: _ctx.pagesNumber,
              "max-pages": 10,
              "boundary-numbers": "",
              size: "sm",
              "direction-links": ""
            }, null, 8, ["modelValue", "max"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "row justify-center q-mt-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_pagination = _resolveComponent("q-pagination")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_q_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_q_card_section, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_table, {
              flat: "",
              title: _ctx.t('Access requests'),
              rows: _ctx.accessRequests,
              columns: _ctx.columns,
              "row-key": "uuid",
              "no-data-label": _ctx.t('No data was found'),
              pagination: _ctx.pagination,
              "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => (_ctx.pagination = $event)),
              loading: _ctx.loadingAccessRequests,
              onRequest: _ctx.loadAccessRequests,
              grid: false,
              "binary-state-sort": "",
              "hide-bottom": ""
            }, {
              "top-right": _withCtx(() => [
                _createVNode(_component_q_toggle, {
                  dense: "",
                  modelValue: _ctx.showAcceptedAccessRequests,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAcceptedAccessRequests = $event)),
                  label: _ctx.t('Show accepted access requests')
                }, null, 8, ["modelValue", "label"])
              ]),
              body: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_td, {
                      key: "name",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.getFullName()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "email",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.email), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "market",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.market.country), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "role",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.role), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "created_at",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatDate(props.row.created_at || '')), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "accepted",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        (!props.row.accepted)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              class: "q-ml-md",
                              size: "sm",
                              color: "primary",
                              onClick: ($event: any) => (_ctx.openConfirmDialog(props.row))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Accept')), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true),
                        (props.row.accepted)
                          ? (_openBlock(), _createBlock("div", _hoisted_1, _toDisplayString(_ctx.t('Accepted on')) + " " + _toDisplayString(_ctx.formatDate(props.row.accepted_at || '')) + " " + _toDisplayString(_ctx.t('by')) + " " + _toDisplayString(props.row.accepted_by?.firstname), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 1
            }, 8, ["title", "rows", "columns", "no-data-label", "pagination", "loading", "onRequest"]),
            _createVNode("div", _hoisted_2, [
              _createVNode(_component_q_pagination, {
                modelValue: _ctx.pagination.page,
                "onUpdate:modelValue": [
                  _cache[3] || (_cache[3] = ($event: any) => (_ctx.pagination.page = $event)),
                  _cache[4] || (_cache[4] = ($event: any) => (_ctx.loadAccessRequests({ pagination: _ctx.pagination })))
                ],
                color: "primary",
                max: _ctx.pagesNumber,
                "max-pages": 10,
                "boundary-numbers": "",
                size: "sm",
                "direction-links": ""
              }, null, 8, ["modelValue", "max"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.showConfirmDialog,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.showConfirmDialog = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_confirm_dialog, {
          "is-processing": _ctx.isProcessingAcceptment,
          title: 
                'Are you sure you want to accept the access request of ' +
                _ctx.accessRequestAccepting.getFullName() +
                '?'
            ,
          onConfirm: _ctx.acceptRequest,
          onCancel: _ctx.closeDialog
        }, null, 8, ["is-processing", "title", "onConfirm", "onCancel"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import i18n from './i18n';
import auth from './router/middleware/auth';
import guest from './router/middleware/guest';
import admin from './router/middleware/admin';
import moderator from './router/middleware/moderator';

const app = createApp(App);
app.use(Quasar, quasarUserOptions);
app.use(i18n);
app.use(store);
app.use(router);

// load middleware
auth.load(router, store, i18n);
guest.load(router, store);
admin.load(router, store);
moderator.load(router, store);

app.mount('#app');

import api, { PaginatedApiMeta } from '@/api/core/api';
import Market, { MarketData } from './Market';

export enum UserLanguage {
    EN = 'en',
}

export enum UserRole {
    USER = 'User',
    MODERATOR = 'Moderator',
    ADMINISTRATOR = 'Administrator',
}

export interface UserData {
    uuid?: string;
    firstname?: string;
    middlename?: string;
    lastname?: string;
    email?: string;
    market?: MarketData;
    role?: UserRole;
    language?: UserLanguage;
    active?: boolean;
    created_at?: string;
}

export interface PasswordData {
    current_password: string;
    new_password: string;
    new_password_confirmation: string;
}

export default class User implements UserData {
    uuid?: string;
    firstname?: string;
    middlename?: string;
    lastname?: string;
    email?: string;
    market?: Market;
    role?: UserRole;
    language?: UserLanguage;
    active?: boolean;
    created_at?: string;

    constructor(data: UserData) {
        this.uuid = data.uuid;
        this.firstname = data.firstname;
        this.middlename = data.middlename;
        this.lastname = data.lastname;
        this.email = data.email;
        this.market = data.market;
        this.role = data.role;
        this.language = data.language || UserLanguage.EN;
        this.active = data.active || false;
        this.created_at = data.created_at;
    }

    getFullName(): string {
        return `${this.firstname || ''} ${this.middlename || ''} ${this.lastname || ''}`.replace(
            '  ',
            ' '
        );
    }

    static async signIn(email: string, password: string): Promise<User> {
        const response = await api.post<UserData>('/login', { email, password });
        return new User(response.data);
    }

    static async signOut(): Promise<void> {
        await api.post<UserData>('/logout');
    }

    static async user(): Promise<User> {
        const response = await api.get<UserData>('/user');
        return new User(response.data);
    }

    static async all(
        config: {
            sortBy?: string;
            ascending?: boolean;
            includeInactiveUsers?: boolean;
            perPage?: number;
            page?: number;
        } = {}
    ): Promise<{ users: User[]; meta: PaginatedApiMeta }> {
        const response = await api.getPaginated<User[]>('/users', {
            sort_by: config.sortBy,
            asc: config.ascending,
            show_only_active: !config.includeInactiveUsers,
            number: config.perPage,
            page: config.page,
        });

        return {
            users: response.data.map((user: User) => new User(user)),
            meta: response.meta,
        };
    }

    async save(): Promise<void> {
        if (this.uuid === undefined) {
            // create a new user
            await api.post<UserData>('/users', {
                firstname: this.firstname,
                middlename: this.middlename,
                lastname: this.lastname,
                email: this.email,
                market_uuid: this.market?.uuid,
                role: this.role,
                language: this.language,
                active: this.active,
            });
        } else {
            // update existing user
            await api.patch<UserData>(`/users/${this.uuid}`, {
                firstname: this.firstname,
                middlename: this.middlename,
                lastname: this.lastname,
                email: this.email,
                market_uuid: this.market?.uuid,
                role: this.role,
                language: this.language,
                active: this.active,
            });
        }
    }

    async savePassword(data: PasswordData): Promise<void> {
        await api.post(`/user/update-password`, { ...data });
    }

    async delete(): Promise<void> {
        await api.delete(`/users/${this.uuid}`);
    }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    options: _ctx.marketOptions,
    modelValue: _ctx.marketModel,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.marketModel = $event)),
    label: _ctx.t('Market'),
    "option-label": "country",
    loading: _ctx.loadingMarkets,
    "options-dense": "",
    readonly: _ctx.readonly
  }, {
    prepend: _withCtx(() => [
      _createVNode(_component_q_icon, { name: "public" })
    ]),
    _: 1
  }, 8, ["options", "modelValue", "label", "loading", "readonly"]))
}
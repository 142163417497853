import api, { PaginatedApiMeta } from '@/api/core/api';
import User, { UserData } from './User';
import Brand, { BrandData } from './Brand';
import Market, { MarketData } from './Market';

export interface FileData {
    uuid: string;
    file_name: string;
    url: string;
}

export interface RequestData {
    id?: number;
    uuid?: string;
    user?: UserData;
    product_number?: string;
    is_360_image?: boolean;
    brand?: BrandData;
    market?: MarketData;
    reference_number?: string;
    files?: FileData[];
    comments?: string;
    created_at?: string;
}

export default class Request implements RequestData {
    id?: number;
    uuid?: string;
    user?: User;
    product_number?: string;
    is_360_image?: boolean;
    brand?: BrandData;
    market?: Market;
    reference_number?: string;
    files?: FileData[];
    comments?: string;
    created_at?: string;

    constructor(data: RequestData) {
        this.id = data.id;
        this.uuid = data.uuid;
        this.user = new User(data.user || {});
        this.product_number = data.product_number;
        this.is_360_image = data.is_360_image;
        this.brand = new Brand(typeof data.brand === 'object' ? data.brand : { name: data.brand });
        this.market = new Market(
            typeof data.market === 'object' ? data.market : { country: data.market }
        );
        this.reference_number = data.reference_number;
        this.files = data.files?.map((file) => {
            return { uuid: file.uuid, file_name: file.file_name, url: file.url };
        });
        this.comments = data.comments;
        this.created_at = data.created_at;
    }

    static async all(
        config: {
            sortBy?: string;
            ascending?: boolean;
            perPage?: number;
            page?: number;
        } = {}
    ): Promise<{ requests: Request[]; meta: PaginatedApiMeta }> {
        const response = await api.getPaginated<Request[]>('/requests', {
            sort_by: config.sortBy,
            asc: config.ascending,
            number: config.perPage,
            page: config.page,
        });

        return {
            requests: response.data.map((request: Request) => new Request(request)),
            meta: response.meta,
        };
    }

    static async get(uuid: string): Promise<Request> {
        const response = await api.get<RequestData>(`/requests/${uuid}`);
        return new Request(response.data);
    }

    static exportAll(): void {
        window.open(`${api.getBaseUrl()}/export`);
    }

    async create(requestFiles: File[], progressHandler: (p: number) => void): Promise<Request> {
        const fd = new FormData();
        const formDataProperties = [
            'product_number',
            'brand',
            'reference_number',
            'is_360_image',
            'brand',
            'market',
            'comments',
        ];
        for (const [key, value] of Object.entries(this)) {
            if (value !== undefined && formDataProperties.includes(key)) {
                switch (key) {
                    case 'brand':
                        fd.append('brand_uuid', value.uuid);
                        break;
                    case 'market':
                        fd.append('market_uuid', value.uuid);
                        break;
                    default:
                        fd.append(key, value);
                }
            }
        }

        for (const file of requestFiles) {
            fd.append('files[]', file);
        }

        const response = await api.post<RequestData>(
            '/requests',
            fd,
            {},
            { 'Content-Type': 'multipart/form-data' },
            (event) => {
                progressHandler(event.loaded / event.total);
            }
        );
        return new Request(response.data);
    }
}

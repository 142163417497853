
import { defineComponent, ref, Ref } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import Password from '@/api/models/Password';
import { ApiServiceUnavailableError, ApiValidationError } from '@/api/core/api';
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
    name: 'PasswordForgottenSection',
    setup(props, { emit }) {
        const { t } = useI18n();

        const showDialog: Ref<boolean> = ref(false);
        const isProcessing: Ref<boolean> = ref(false);

        const email: Ref<string> = ref('');
        const errorMessage: Ref<string> = ref('');
        const error: Ref<boolean> = ref(false);

        const success: Ref<boolean> = ref(false);

        const reset = async () => {
            isProcessing.value = true;
            try {
                await Password.forgotten(email.value);
                showDialog.value = false;
                email.value = '';
                resetSuccessNotification();
                emit('cancelReset');
            } catch (e) {
                error.value = true;
                if (e instanceof ApiValidationError) {
                    errorMessage.value = e.getErrors()[0];
                } else if (e instanceof ApiServiceUnavailableError) {
                    errorMessage.value = e.message;
                }
            } finally {
                isProcessing.value = false;
            }
        };

        const $q = useQuasar();
        const resetSuccessNotification = () => {
            $q.notify({
                color: 'positive',
                message: t(
                    'Password reset request succesful. Check your email for the reset link.'
                ),
                icon: 'check',
                position: 'center',
                progress: true,
                timeout: 8000,
                actions: [
                    {
                        label: t('Hide'),
                        color: 'white',
                        handler: () => {
                            /* ... */
                        },
                    },
                ],
            });
        };

        return {
            t,
            emit,
            showDialog,
            isProcessing,
            reset,
            email,
            errorMessage,
            error,
            success,
        };
    },
});


import { defineComponent, ref, Ref } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { ApiServiceUnavailableError, ApiValidationError } from '@/api/core/api';
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import AccessRequest from '@/api/models/AccessRequest';
import { UserRole } from '@/api/models/User';
import Errors from '@/components/Notifications/Errors.vue';
import MarketSelector from '../MarketSelector.vue';

export default defineComponent({
    name: 'RequestAccessSection',
    emits: ['cancelRequest', 'requested'],
    components: { Errors, MarketSelector },
    setup(props, { emit }) {
        const { t } = useI18n();

        const showDialog: Ref<boolean> = ref(false);
        const errors: Ref<string[]> = ref([]);
        const success: Ref<boolean> = ref(false);
        const isLoading: Ref<boolean> = ref(false);

        const accessRequestModel: Ref<AccessRequest> = ref(
            new AccessRequest({ role: UserRole.USER })
        );

        const requestAccess = async () => {
            try {
                isLoading.value = true;
                await accessRequestModel.value.request();
                isLoading.value = true;
                showDialog.value = false;
                requestAccessSuccessNotification();
                accessRequestModel.value = new AccessRequest({ role: UserRole.USER });
                emit('requested');
            } catch (e) {
                if (e instanceof ApiValidationError) {
                    errors.value = e.getErrors();
                } else if (e instanceof ApiServiceUnavailableError) {
                    errors.value = [e.message];
                }
            } finally {
                isLoading.value = false;
            }
        };

        const $q = useQuasar();
        const requestAccessSuccessNotification = () => {
            $q.notify({
                color: 'positive',
                message: t('Request access submitted sucessfully. Please wait for approval.'),
                icon: 'check',
                position: 'top',
                progress: true,
                timeout: 5000,
                actions: [
                    {
                        label: t('Hide'),
                        color: 'white',
                        handler: () => {
                            /* ... */
                        },
                    },
                ],
            });
        };

        const userRoleOptions = Object.values(UserRole).map((role) => ({
            label: t(role),
            value: role,
        }));

        return {
            t,
            emit,
            isLoading,
            accessRequestModel,
            showDialog,
            userRoleOptions,
            requestAccess,
            errors,
            success,
        };
    },
});

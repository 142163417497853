import User from '@/api/models/User';

export interface State {
    user: User | null;
    isAuthenticating: boolean;
}

const state: State = {
    user: null,
    isAuthenticating: false,
};

export default state;

import api from '@/api/core/api';

export interface BrandData {
    uuid?: string;
    name?: string;
}

export default class Brand implements BrandData {
    uuid?: string;
    name?: string;

    constructor(data: BrandData) {
        this.uuid = data.uuid;
        this.name = data.name;
    }

    static async all(): Promise<Brand[]> {
        const response = await api.get<BrandData[]>('/brands');
        return response.data.map((m) => new Brand(m));
    }
}

{
  "Ordertool": "Ordertool",
  "Authenticating": "Authenticating",
  "Password": "Password",
  "Password forgotten": "Password forgotten",
  "Reset password": "Reset password",
  "These credentials are wrong": "These credentials are wrong",
  "Your account is inactive": "Your account is inactive",
  "Email": "Email",
  "Cancel": "Cancel",
  "Hide": "Hide",
  "Sign in": "Sign in",
  "Requests": "Requests",
  "Users": "Users",
  "Edit user": "Edit user",
  "Create user": "Create user",
  "Firstname": "Firstname",
  "Middlename": "Middlename",
  "Lastname": "Lastname",
  "Role": "Role",
  "Active": "Active",
  "Delete": "Delete",
  "Save": "Save",
  "Show inactive users": "Show inactive users",
  "Name": "Name",
  "Date created": "Date created",
  "Create request": "Create request",
  "Article": "Article",
  "Segment": "Segment",
  "Item number": "Object numer",
  "Brand": "Brand",
  "Product name as on facing": "Product name as on facing",
  "Kind of packaging": "Kind of packaging",
  "Contents": "Contents",
  "Language": "Language",
  "Artwork": "Artwork",
  "Artwork number": "Artwork number",
  "Article comments": "Article comments",
  "Files": "Files",
  "No data was found": "No data was found",
  "Product name": "Product name",
  "Applicant": "Applicant",
  "Password reset request succesful. Check your email for the reset link.": "Password reset request succesful. Check your email for the reset link.",
  "My account": "My account",
  "My password": "My password",
  "Your password is updated successfully": "Your password is updated successfully",
  "Back": "Back",
  "Submit request": "Submit request",
  "Please wait, your request is being submitted": "Please wait, your request is being submitted",
  "Request": "Request",
  "Requests overview": "Requests overview",
  "Request ID": "Request ID",
  "Professional": "Professional",
  "Consumer": "Consumer",
  "Sorry, the page or resource you are looking for could not be found": "Sorry, the page or resource you are looking for could not be found",
  "User": "User",
  "Administrator": "Administrator",
  "Moderator": "Moderator",
  "Current password": "Current password",
  "Password confirmation": "Password confirmation",
  "Submitted at": "Submitted at",
  "Access requests": "Access requests",
  "Show accepted access requests": "Show accepted access requests",
  "Accept": "Accept",
  "Accepted on": "Accepted on",
  "Request access": "Request access",
  "After successfully sending the request form, you will receive the requested images within a few business days. If you have more urgent requirements or changes in the request, add this to the comments or send a separate email to bostik-packshot{'@'}peekcreativestudios.nl.": "After successfully sending the request form, you will receive the requested images within a few business days. If you have more urgent requirements or changes in the request, add this to the comments or send a separate email to bostik-packshot{'@'}peekcreativestudios.nl.",
  "Product number": "Product number",
  "Reference number": "Reference number",
  "Other": "Other",
  "Reference number available": "Reference number available",
  "Requested role": "Requested role",
  "Date requested": "Date requested",
  "Confirm": "Confirm",
  "Packshot": "Packshot",
  "Please send your artwork in high-res certified PDF.": "Please send your artwork in high-res certified PDF.",
  "Remarks: Make sure it is a layered PDF. This way, the cut lines can be easily removed.": "Remarks: Make sure it is a layered PDF. This way, the cut lines can be easily removed.",
  "validation": {
    "required": "{fieldName} is required.",
    "email": "Please enter a valid email address.",
    "maxLength": "{fieldName} cannot be larger than {length} characters.",
    "atLeastOne": "Select at least one {name}.",
    "strongPassword": "Passwords should contain at least 8 characters, a letter, a capital letter and a number."
  }
}
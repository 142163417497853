{
  "Ordertool": "Bestellingstool",
  "Authenticating": "Authenticatie",
  "Password": "Wachtwoord",
  "Password forgotten": "Wachtwoord vergeten",
  "Reset password": "Reset wachtwoord",
  "These credentials are wrong": "Deze credentials zijn incorrect",
  "Your account is inactive": "Jouw account is inactief",
  "Email": "Email",
  "Cancel": "Annuleren",
  "Hide": "Verbergen",
  "Sign in": "Inloggen",
  "Requests": "Aanvragen",
  "Users": "Gebruikers",
  "Edit user": "Bewerk gebruiker",
  "Create user": "Maak gebruiker aan",
  "Firstname": "Voornaam",
  "Middlename": "Tussenvoegsel(s)",
  "Lastname": "Achternaam",
  "Role": "Rol",
  "Active": "Actief",
  "Delete": "Verwijderen",
  "Save": "Opslaan",
  "Show inactive users": "Laat inactieve gebruikers zien",
  "Name": "Naam",
  "Date created": "Aanmaakdatum",
  "Create request": "Request maken",
  "Article": "Artikel",
  "Segment": "Segment",
  "Item number": "Object numer",
  "Brand": "Merk",
  "Product name as on facing": "Product naam op verpakking",
  "Kind of packaging": "Verpakingssoort",
  "Contents": "Inhoud",
  "Language": "Taal",
  "Artwork": "Kunstwerk",
  "Artwork number": "Kunstwerk nummer",
  "Article comments": "Artikel opmerkingen",
  "Files": "Bestanden",
  "No data was found": "Er is geen data gevonden",
  "Product name": "Product naam",
  "Applicant": "Aanvrager",
  "Password reset request succesful. Check your email for the reset link.": "Wachtwoord herstel aanvraag gelukt. Bekijk je email voor de herstellink.",
  "My account": "Mijn account",
  "My password": "Mijn wachtwoord",
  "Your password is updated successfully": "Je wachtwoord is succesvol bijgewerkt",
  "Back": "Terug",
  "Submit request": "Verstuur aanvraag",
  "Please wait, your request is being submitted": "Even geduld, uw aanvraag is aan het versturen",
  "Request": "Aanvraag",
  "Requests overview": "Aanvragen overzicht",
  "Request ID": "Aanvraag ID",
  "Professional": "Professional",
  "Consumer": "Consument",
  "Sorry, the page or resource you are looking for could not be found": "Sorry, de pagina of resource die je probeert te bereiken kan niet worden gevonden",
  "User": "Gebruik",
  "Administrator": "Administrator",
  "Moderator": "Moderator",
  "Current password": "Huidig wachtwoord",
  "Password confirmation": "Wachtwoord bevestiging",
  "Submitted at": "Aangevraag op",
  "Access requests": "Toegangsaanvragen",
  "Show accepted access requests": "Toon geacepteerde toegangsaanvragen",
  "Accept": "Accepteer",
  "Accepted on": "Geaccepteerd op",
  "Request access": "Vraag toegang aan",
  "After successfully sending the request form, you will receive the requested images within a few business days. If you have more urgent requirements or changes in the request, add this to the comments or send a separate email to bostik-packshot{'@'}peekcreativestudios.nl.": "Na het succesvol verzenden van het aanvraagformulier ontvang je binnen enkele werkdagen de gevraagde afbeeldingen. Heb je dringendere wensen of wijzigingen in het verzoek, voeg dit dan toe bij de opmerkingen of stuur een aparte mail naar bostik-packshot{'@'}peekcreativestudios.nl.",
  "Product number": "Product nummber",
  "Reference number": "Referentie nummer",
  "Other": "Anders",
  "Reference number available": "Referentie nummer beschikbaar",
  "Requested role": "Rol aangevraagd",
  "Date requested": "Datum aanvraag",
  "Confirm": "Bevestig",
  "Packshot": "Packshot",
  "Please send your artwork in high-res certified PDF.": "Verstuur je artwork alsjeblieft in high-res certified PDF.",
  "Remarks: Make sure it is a layered PDF. This way, the cut lines can be easily removed.": "Let op: Zorg ervoor dat het een gelaagde PDF is. Op die manier, kunnen de snijlijnen makkelijk verwijderd worden."
}

import { ApiValidationError } from '@/api/core/api';
import User, { UserRole } from '@/api/models/User';
import CrudDialog from '@/components/Interaction/CrudDialog.vue';
import { defineComponent, Ref, ref, WritableComputedRef, computed } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import MarketSelector from './MarketSelector.vue';

export default defineComponent({
    components: { CrudDialog, MarketSelector },
    props: {
        user: {
            default: null,
            type: User,
        },
    },
    name: 'UserOverview',
    setup(props, { emit }) {
        const { t } = useI18n();
        const store = useStore();

        const users: Ref<User[]> = ref([]);
        const isModerator = computed(() => store.getters.isModerator);
        const isAdmin = computed(() => store.getters.isAdmin);
        const isMe = computed(() => store.getters.isMe(props.user));

        const userModel: WritableComputedRef<User> = computed({
            get: () => props.user,
            set: (u: User) => {
                emit('update:user', u);
            },
        });

        const UserRoleOptions = Object.values(UserRole).map((role) => ({
            label: t(role),
            value: role,
        }));

        const dialogErrors: Ref<string[]> = ref([]);
        const isProcessingUser: Ref<boolean> = ref(false);

        /**
         * Actions
         */
        const createUser = () => {
            userModel.value = new User({});
            dialogErrors.value = [];
            emit('close');
        };

        const deleteUser = async () => {
            isProcessingUser.value = true;
            try {
                await userModel.value.delete();
                emit('close');
            } catch (e) {
                if (e instanceof ApiValidationError) {
                    dialogErrors.value = e.getErrors();
                } else {
                    throw e;
                }
            } finally {
                isProcessingUser.value = false;
            }
        };

        const saveUser = async () => {
            dialogErrors.value = [];
            isProcessingUser.value = true;

            try {
                await userModel.value.save();
                emit('close');
            } catch (e) {
                if (e instanceof ApiValidationError) {
                    dialogErrors.value = e.getErrors();
                } else {
                    throw e;
                }
            } finally {
                isProcessingUser.value = false;
            }
        };

        return {
            t,
            isAdmin,
            isModerator,
            isMe,
            users,
            UserRoleOptions,
            userModel,
            isProcessingUser,
            dialogErrors,
            createUser,
            deleteUser,
            saveUser,
        };
    },
});


import { formatDate } from '@/util/formatters';
import { QPaginationMeta } from '@/util/pagination';
import User from '@/api/models/User';
import {
    defineComponent,
    onBeforeMount,
    Ref,
    ref,
    ComputedRef,
    computed,
    watch,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import UserDetails from '@/components/UserDetails.vue';

export default defineComponent({
    name: 'UserOverview',
    components: { UserDetails },
    setup() {
        const { t } = useI18n();
        const store = useStore();

        const user: ComputedRef<User> = computed(() => store.getters.user);
        const users: Ref<User[]> = ref([]);
        const isAdmin = computed(() => store.getters.isAdmin);

        const columns = [
            {
                label: t('First name'),
                field: 'firstname',
                name: 'firstname',
                align: 'left',
                sortable: true,
            },
            {
                label: t('Middle name'),
                field: 'middlename',
                name: 'middlename',
                align: 'left',
                sortable: true,
            },
            {
                label: t('Last name'),
                field: 'lastname',
                name: 'lastname',
                align: 'left',
                sortable: true,
            },
            { label: t('Email'), field: 'email', name: 'email', align: 'left', sortable: true },
            {
                label: t('Role'),
                field: (user: User) => t(user.role || ''),
                name: 'role',
                align: 'left',
                sortable: true,
            },
            {
                label: t('Date created'),
                field: (user: User) => formatDate(user.created_at || ''),
                name: 'created_at',
                align: 'left',
                sortable: true,
            },
        ];

        const loadingUsers: Ref<boolean> = ref(true);

        const pagination: Ref<QPaginationMeta> = ref({
            sortBy: 'lastname',
            descending: true,
            page: 1,
            rowsPerPage: 10,
            rowsNumber: 0,
        });

        const loadUsers = async (props: { pagination: QPaginationMeta }) => {
            loadingUsers.value = true;

            const paginatedUsers = await User.all({
                sortBy: props.pagination.sortBy,
                ascending: !props.pagination.descending,
                includeInactiveUsers: showInactiveUsers.value,
                perPage: props.pagination.rowsPerPage,
                page: props.pagination.page,
            });

            users.value = paginatedUsers.users;
            pagination.value.rowsNumber = paginatedUsers.meta.total;
            pagination.value.page = paginatedUsers.meta.current_page;
            pagination.value.rowsPerPage = paginatedUsers.meta.per_page;
            pagination.value.sortBy = props.pagination.sortBy;
            pagination.value.descending = props.pagination.descending;

            loadingUsers.value = false;
        };

        const inactiveRowStyle = {
            opacity: 0.5,
        };

        onBeforeMount(async () => await loadUsers({ pagination: pagination.value }));

        const showInactiveUsers: Ref<boolean> = ref(false);
        const showUserDialog: Ref<boolean> = ref(false);

        const openDialog = (user: User) => {
            userModel.value = new User({ ...user });
            showUserDialog.value = true;
        };

        const closeDialog = () => {
            showUserDialog.value = false;
            loadUsers({ pagination: pagination.value });
        };

        const userModel: Ref<User> = ref(new User({}));

        watch([showInactiveUsers], () => {
            loadUsers({ pagination: { ...pagination.value, page: 1 } });
        });

        const dateFormatter = (date: string) => formatDate(date);

        return {
            t,
            pagination,
            isAdmin,
            user,
            users,
            inactiveRowStyle,
            showInactiveUsers,
            columns,
            loadUsers,
            loadingUsers,
            showUserDialog,
            userModel,
            openDialog,
            closeDialog,
            dateFormatter,
            pagesNumber: computed(() =>
                Math.ceil(pagination.value.rowsNumber / pagination.value.rowsPerPage)
            ),
        };
    },
});

import axios, { AxiosError, AxiosInstance, AxiosResponse, Method } from 'axios';
import setLanguage from '@/api/middleware/language';

export interface ApiResponse<N> {
    data: N;
}

export interface PaginatedApiMeta {
    current_page: number;
    last_page: number;
    from: number;
    to: number;
    per_page: number;
    total: number;
}

export interface PaginatedApiResponse<N> extends ApiResponse<N> {
    meta: PaginatedApiMeta;
}

export class ApiAuthenticationError implements Error {
    name = 'Api Authentication Error';
    message = 'You are not authenticated.';
}

export class ApiNotFoundError implements Error {
    name = 'Api Not Found Error';
    message = 'The resource could not be found.';
}

export class ApiAuthorizationError implements Error {
    name = 'Api Authorization Error';
    message = 'You are not authorized to perform this action.';
}

export class ApiValidationError implements Error {
    name = 'Api Authorization Error';

    constructor(public message: string, private errors: { [field: string]: string[] }) {}

    getErrors(): string[] {
        return Object.values(this.errors).reduce((acc, m) => [...acc, ...m], []);
    }
}

export class ApiServiceUnavailableError implements Error {
    name = 'Service unavailable error';
    message = 'Service currently unavailable. Try again later.';
}

class Api {
    private client: AxiosInstance;
    private isSessionInitialized = false;

    constructor() {
        this.client = axios.create({
            baseURL: process.env.VUE_APP_API_BASE_URL + '/api',
            withCredentials: true,
        });

        this.client.interceptors.response.use((r) => r, this.errorHandler);
        this.client.interceptors.request.use((r) => setLanguage(r));
    }

    public getBaseUrl() {
        return this.client.defaults.baseURL;
    }

    async initSession() {
        await this.client.get('/sanctum/csrf-cookie', {
            baseURL: process.env.VUE_APP_API_BASE_URL,
        });
        this.isSessionInitialized = true;
    }

    async get<R>(url: string, params = {}, headers = {}): Promise<ApiResponse<R>> {
        const response: AxiosResponse<{ data: R }> = await this.request(
            'GET',
            url,
            {},
            params,
            headers
        );

        return { data: response.data.data };
    }

    async getPaginated<R>(
        url: string,
        params = {},
        headers = {}
    ): Promise<PaginatedApiResponse<R>> {
        const response: AxiosResponse<{ data: R; meta: PaginatedApiMeta }> = await this.request(
            'GET',
            url,
            {},
            params,
            headers
        );

        return { data: response.data.data, meta: response.data.meta };
    }

    async post<R>(
        url: string,
        data = {},
        params = {},
        headers = {},
        onUploadProgress?: (progressEvent: { loaded: number; total: number }) => void
    ): Promise<ApiResponse<R>> {
        const response: AxiosResponse<{ data: R }> = await this.request(
            'POST',
            url,
            data,
            params,
            headers,
            onUploadProgress
        );

        return { data: response.data.data };
    }

    async patch<R>(url: string, data = {}, params = {}, headers = {}): Promise<ApiResponse<R>> {
        const response: AxiosResponse<{ data: R }> = await this.request(
            'PATCH',
            url,
            data,
            params,
            headers
        );

        return { data: response.data.data };
    }

    async delete<R>(url: string, params = {}, headers = {}): Promise<ApiResponse<R>> {
        const response: AxiosResponse<{ data: R }> = await this.request(
            'DELETE',
            url,
            {},
            params,
            headers
        );

        return { data: response.data.data };
    }

    async request<R>(
        method: Method,
        url: string,
        data = {},
        params = {},
        headers = {},
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onUploadProgress?: (progressEvent: { total: number; loaded: number }) => void
    ): Promise<AxiosResponse<R>> {
        if (!this.isSessionInitialized) {
            await this.initSession();
        }

        return await this.client.request({
            method,
            url,
            data,
            params,
            headers,
            onUploadProgress,
        });
    }

    errorHandler(error: AxiosError<unknown>) {
        switch (error.response?.status) {
            case 401: {
                return Promise.reject(new ApiAuthenticationError());
            }
            case 403: {
                return Promise.reject(new ApiAuthorizationError());
            }
            case 404: {
                return Promise.reject(new ApiNotFoundError());
            }
            case 422: {
                const validationErrorResponse = error.response.data as {
                    message: string;
                    errors: { [field: string]: string[] };
                };
                return Promise.reject(
                    new ApiValidationError(
                        validationErrorResponse.message,
                        validationErrorResponse.errors
                    )
                );
            }
            case 503: {
                return Promise.reject(new ApiServiceUnavailableError());
            }
            default:
                return Promise.reject(error);
        }
    }
}

export default new Api();

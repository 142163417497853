
import { formatDate } from '@/util/formatters';
import { QPaginationMeta } from '@/util/pagination';
import Request from '@/api/models/Request';
import { defineComponent, onBeforeMount, Ref, ref, computed } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'UserOverview',
    setup() {
        const { t } = useI18n();
        const store = useStore();
        const router = useRouter();

        const isAdmin = computed(() => store.getters.isAdmin);

        const requests: Ref<Request[]> = ref([]);

        const columns = [
            {
                label: t('Request ID'),
                field: 'id',
                name: 'id',
                align: 'left',
                sortable: true,
            },
            {
                label: t('Product number'),
                field: 'product_number',
                name: 'product_number',
                align: 'left',
                sortable: true,
            },
            {
                label: t('Brand'),
                field: (request: Request) => request.brand?.name || '',
                name: 'brand',
                align: 'left',
                sortable: true,
            },
            {
                label: t('Market'),
                field: (request: Request) => request.market?.country || '',
                name: 'market',
                align: 'left',
                sortable: true,
            },
            {
                label: t('Applicant'),
                field: (request: Request) => request.user?.getFullName() || '',
                name: 'applicant',
                align: 'left',
                sortable: false,
            },
            {
                label: t('Date created'),
                field: (request: Request) => formatDate(request.created_at || ''),
                name: 'created_at',
                align: 'left',
                sortable: true,
            },
        ];

        const loadingRequests: Ref<boolean> = ref(true);

        const pagination: Ref<QPaginationMeta> = ref({
            sortBy: 'created_at',
            descending: true,
            page: 1,
            rowsPerPage: 10,
            rowsNumber: 0,
        });

        const loadRequests = async (props: { pagination: QPaginationMeta }) => {
            loadingRequests.value = true;

            const paginatedRequests = await Request.all({
                sortBy: props.pagination.sortBy,
                ascending: !props.pagination.descending,
                perPage: props.pagination.rowsPerPage,
                page: props.pagination.page,
            });

            requests.value = paginatedRequests.requests;
            pagination.value.rowsNumber = paginatedRequests.meta.total;
            pagination.value.page = paginatedRequests.meta.current_page;
            pagination.value.rowsPerPage = paginatedRequests.meta.per_page;
            pagination.value.sortBy = props.pagination.sortBy;
            pagination.value.descending = props.pagination.descending;

            loadingRequests.value = false;
        };

        onBeforeMount(async () => await loadRequests({ pagination: pagination.value }));

        const newRequest = () => {
            router.push({ name: 'request.create' });
        };

        const showRequest = (e: Event, request: Request) => {
            router.push({ name: 'request.details', params: { request: request.uuid as string } });
        };

        const exportRequests = () => {
            Request.exportAll();
        };

        return {
            t,
            isAdmin,
            pagination,
            requests,
            columns,
            loadRequests,
            loadingRequests,
            exportRequests,
            newRequest,
            showRequest,
            pagesNumber: computed(() =>
                Math.ceil(pagination.value.rowsNumber / pagination.value.rowsPerPage)
            ),
        };
    },
});

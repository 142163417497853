
import { ApiAuthenticationError, ApiValidationError, ApiAuthorizationError } from '@/api/core/api';
import Errors from '@/components/Notifications/Errors.vue';
import { defineComponent, ref, Ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { MutationTypes } from '@/store/mutations';
import User from '@/api/models/User';
import PasswordForgottenSection from '@/components/Interaction/PasswordForgottenSection.vue';
import RequestAccessSection from '@/components/Interaction/RequestAccessSection.vue';

export default defineComponent({
    components: { Errors, PasswordForgottenSection, RequestAccessSection },
    setup() {
        const i18n = useI18n();
        const store = useStore();
        const router = useRouter();

        const email: Ref<string> = ref('');
        const password: Ref<string> = ref('');
        const errors: Ref<string[]> = ref([]);
        const isLoading: Ref<boolean> = ref(false);
        const passwordReset: Ref<boolean> = ref(false);
        const accessSubmission: Ref<boolean> = ref(false);

        const signIn = async () => {
            if (isLoading.value) return;

            isLoading.value = true;
            errors.value = [];

            try {
                const user = await User.signIn(email.value, password.value);
                store.commit(MutationTypes.setUser, user);
                i18n.locale.value = user.language || i18n.locale.value;
                router.replace({ name: 'request.overview' });
            } catch (e) {
                if (e instanceof ApiAuthenticationError) {
                    errors.value = [i18n.t('These credentials are wrong')];
                } else if (e instanceof ApiAuthorizationError) {
                    errors.value = [i18n.t('Your account is inactive')];
                } else if (e instanceof ApiValidationError) {
                    errors.value = e.getErrors();
                } else {
                    throw e;
                }
            } finally {
                isLoading.value = false;
            }
        };

        onMounted(() => {
            if (store.getters.isAuthenticated) {
                router.replace({ name: 'request.overview' });
            }
        });

        return {
            t: i18n.t,
            email,
            password,
            signIn,
            errors,
            isLoading,
            passwordReset,
            accessSubmission,
        };
    },
});

import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_openBlock(), _createBlock(_component_q_card_section, { class: "success-notification" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_banner, {
        rounded: "",
        dense: "",
        class: "text-white bg-green q-mb-sm"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      })
    ]),
    _: 1
  }))
}
import { RouteRecordRaw } from 'vue-router';
import SignIn from '@/views/SignIn.vue';
import RequestsOverview from '@/views/Requests/RequestOverview.vue';
import RequestCreate from '@/views/Requests/RequestCreate.vue';
import RequestDetails from '@/views/Requests/RequestDetails.vue';
import UserOverview from '@/views/UserOverview.vue';
import AccessRequestsOverview from '@/views/AccessRequestsOverview.vue';
import MyAccount from '@/views/MyAccount.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import ResetPassword from '@/views/ResetPassword.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: PageNotFound,
    },
    { path: '/', redirect: { name: 'request.overview' } },
    {
        path: '/login',
        name: 'sign-in',
        component: SignIn,
        meta: {
            guest: true,
        },
    },
    {
        path: '/reset-password/:token/:email',
        name: 'reset-password',
        component: ResetPassword,
        meta: {
            guest: true,
        },
    },
    {
        path: '/requests',
        name: 'request.overview',
        component: RequestsOverview,
        meta: {
            auth: true,
        },
    },
    {
        path: '/requests/create',
        name: 'request.create',
        component: RequestCreate,
        meta: {
            auth: true,
        },
    },
    {
        path: '/requests/:request',
        name: 'request.details',
        component: RequestDetails,
        meta: {
            auth: true,
        },
    },
    {
        path: '/users',
        name: 'users.overview',
        component: UserOverview,
        meta: {
            auth: true,
            moderator: true,
        },
    },
    {
        path: '/access-requests',
        name: 'access-requests.overview',
        component: AccessRequestsOverview,
        meta: {
            auth: true,
            admin: true,
        },
    },
    {
        path: '/my-account',
        name: 'my-account',
        component: MyAccount,
        meta: {
            auth: true,
        },
    },
];

export default routes;

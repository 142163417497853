
import { ApiValidationError } from '@/api/core/api';
import Request from '@/api/models/Request';
import BrandSelector from '@/components/BrandSelector.vue';
import { defineComponent, ref, Ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import Errors from '@/components/Notifications/Errors.vue';
import MarketSelector from '@/components/MarketSelector.vue';
import { useStore } from 'vuex';

export default defineComponent({
    components: { BrandSelector, Errors, MarketSelector },
    setup() {
        const { t } = useI18n();
        const store = useStore();
        const router = useRouter();

        const request = ref(new Request({ is_360_image: true, market: store.getters.user.market }));
        const requestFiles: Ref<File[]> = ref([]);
        const hasReferenceNumber: Ref<boolean> = ref(true);

        const dialogErrors: Ref<string[]> = ref([]);
        const isProcessingRequest: Ref<boolean> = ref(false);
        const progressValue: Ref<number> = ref(0);

        const createRequest = async () => {
            dialogErrors.value = [];
            isProcessingRequest.value = true;

            const updateProgressValue = (progress: number) => {
                progressValue.value = progress * 0.9;
            };

            try {
                const createdRequest = await request.value.create(
                    requestFiles.value,
                    updateProgressValue
                );

                progressValue.value = 1;

                setTimeout(() => {
                    isProcessingRequest.value = false;
                    progressValue.value = 0;

                    router.push({
                        name: 'request.details',
                        params: { request: createdRequest.uuid as string },
                    });
                }, 300);
            } catch (e) {
                if (e instanceof ApiValidationError) {
                    dialogErrors.value = e.getErrors();
                    isProcessingRequest.value = false;
                    progressValue.value = 0;
                } else {
                    throw e;
                }
            }
        };

        watch([hasReferenceNumber], () => {
            if (!hasReferenceNumber.value) {
                request.value.reference_number = undefined;
            }
        });

        return {
            t,
            request,
            requestFiles,
            hasReferenceNumber,
            isProcessingRequest,
            progressValue,
            dialogErrors,
            createRequest,
        };
    },
});

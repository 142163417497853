import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "row items-center justify-center window-height",
  style: {"color":"white","size":"20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "bg-primary" }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, _toDisplayString(_ctx.t('Sorry, the page or resource you are looking for could not be found')), 1)
    ]),
    _: 1
  }))
}
import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-h6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_spinner_gears = _resolveComponent("q-spinner-gears")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, { style: {"width":"600px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, { class: "row items-center" }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }),
      _createVNode(_component_q_card_actions, { class: "q-pa-md" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_btn, {
            flat: "",
            label: _ctx.t('Cancel'),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel'))),
            "text-color": "negative"
          }, null, 8, ["label"]),
          _createVNode(_component_q_space),
          _createVNode(_component_q_btn, {
            label: _ctx.t('Confirm'),
            color: "primary",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('confirm'))),
            class: "q-ml-md"
          }, null, 8, ["label"])
        ]),
        _: 1
      }),
      _createVNode(_component_q_inner_loading, { showing: _ctx.isProcessing }, {
        default: _withCtx(() => [
          _createVNode(_component_q_spinner_gears, {
            size: "50px",
            color: "primary"
          })
        ]),
        _: 1
      }, 8, ["showing"])
    ]),
    _: 1
  }))
}

import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    components: {},
    name: 'ConfirmDialog',
    emits: ['cancel', 'confirm'],
    setup() {
        const { t } = useI18n();

        return { t };
    },
    props: {
        /**
         * Displayed at the top of the dialog.
         */
        title: {
            type: String,
            required: true,
        },
        /**
         * Whether an async action is currently running.
         */
        isProcessing: {
            type: Boolean,
            default: false,
        },
    },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
import User, { UserRole } from '@/api/models/User';
import { State } from './state';

export default {
    user: (state: State): User | null => state.user,
    isAuthenticated: (state: State): boolean => state.user !== null,
    isAuthenticating: (state: State): boolean => state.isAuthenticating,
    isAdmin: (state: State): boolean => state.user?.role == UserRole.ADMINISTRATOR || false,
    isModerator: (state: State): boolean => state.user?.role == UserRole.MODERATOR || false,
    isMe:
        (state: State, getters: any) =>
        (user: User): boolean =>
            getters.isAuthenticated && getters.user.uuid == user.uuid,
};

import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_q_input, {
      type: "email",
      modelValue: _ctx.email,
      "onUpdate:modelValue": [
        _cache[1] || (_cache[1] = ($event: any) => (_ctx.email = $event)),
        _cache[2] || (_cache[2] = ($event: any) => (_ctx.error ? (_ctx.error = !_ctx.error) : false))
      ],
      label: _ctx.t('Email'),
      clearable: "",
      "bottom-slots": "",
      "error-message": _ctx.errorMessage,
      error: _ctx.error,
      onKeydown: _withKeys(_ctx.reset, ["enter"]),
      class: "q-mb-md"
    }, null, 8, ["modelValue", "label", "error-message", "error", "onKeydown"]),
    _createVNode(_component_q_btn, {
      onClick: _ctx.reset,
      label: _ctx.t('Reset password'),
      class: "full-width q-mb-md",
      color: "primary",
      loading: _ctx.isProcessing
    }, null, 8, ["onClick", "label", "loading"]),
    _createVNode(_component_q_btn, {
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.emit('cancelReset'))),
      label: _ctx.t('Cancel'),
      class: "full-width",
      flat: "",
      color: "grey"
    }, null, 8, ["label"])
  ], 64))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row q-col-gutter-md items-center q-mb-md" }
const _hoisted_2 = { class: "col-xs-12 col-md-4" }
const _hoisted_3 = { class: "col-xs-12 col-md-4" }
const _hoisted_4 = { class: "col-xs-12 col-md-4" }
const _hoisted_5 = { class: "row q-col-gutter-md items-center" }
const _hoisted_6 = { class: "col-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_market_selector = _resolveComponent("market-selector")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_crud_dialog = _resolveComponent("crud-dialog")!

  return (_openBlock(), _createBlock(_component_crud_dialog, {
    errors: _ctx.dialogErrors,
    "is-processing": _ctx.isProcessingUser,
    title: _ctx.userModel.uuid ? _ctx.t('Edit user') : _ctx.t('Create user'),
    "with-save": _ctx.isAdmin,
    "with-delete": _ctx.isAdmin && !_ctx.isMe,
    onSave: _ctx.saveUser,
    onDelete: _ctx.deleteUser
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_q_input, {
            readonly: _ctx.isModerator,
            modelValue: _ctx.userModel.firstname,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.userModel.firstname = $event)),
            label: _ctx.t('First name')
          }, null, 8, ["readonly", "modelValue", "label"])
        ]),
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_q_input, {
            readonly: _ctx.isModerator,
            modelValue: _ctx.userModel.middlename,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.userModel.middlename = $event)),
            label: _ctx.t('Middle name')
          }, null, 8, ["readonly", "modelValue", "label"])
        ]),
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_q_input, {
            readonly: _ctx.isModerator,
            modelValue: _ctx.userModel.lastname,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.userModel.lastname = $event)),
            label: _ctx.t('Last name')
          }, null, 8, ["readonly", "modelValue", "label"])
        ])
      ]),
      _createVNode(_component_q_input, {
        class: "q-mb-md",
        readonly: _ctx.isModerator,
        modelValue: _ctx.userModel.email,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.userModel.email = $event)),
        label: _ctx.t('Email')
      }, null, 8, ["readonly", "modelValue", "label"]),
      _createVNode(_component_market_selector, {
        class: "q-mb-md",
        readonly: _ctx.isModerator,
        modelValue: _ctx.userModel.market,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.userModel.market = $event))
      }, null, 8, ["readonly", "modelValue"]),
      _createVNode("div", _hoisted_5, [
        _createVNode("div", _hoisted_6, [
          _createVNode(_component_q_select, {
            readonly: _ctx.isModerator,
            class: "q-mb-md",
            options: _ctx.UserRoleOptions,
            "option-value": "value",
            "option-label": "label",
            modelValue: _ctx.userModel.role,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.userModel.role = $event)),
            label: _ctx.t('Role'),
            "emit-value": "",
            "map-options": "",
            "hide-bottom-space": ""
          }, null, 8, ["readonly", "options", "modelValue", "label"])
        ]),
        _createVNode("div", null, [
          _createVNode(_component_q_toggle, {
            disable: _ctx.isModerator || _ctx.isMe,
            modelValue: _ctx.userModel.active,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.userModel.active = $event)),
            label: _ctx.t('Active')
          }, null, 8, ["disable", "modelValue", "label"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["errors", "is-processing", "title", "with-save", "with-delete", "onSave", "onDelete"]))
}
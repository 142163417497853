
import Request, { FileData } from '@/api/models/Request';
import BrandSelector from '@/components/BrandSelector.vue';
import { defineComponent, ref, Ref, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/util/formatters';
import { useRoute } from 'vue-router';
import { ApiNotFoundError } from '@/api/core/api';
import { useRouter } from 'vue-router';
import axios from 'axios';
import MarketSelector from '@/components/MarketSelector.vue';

export default defineComponent({
    components: { BrandSelector, MarketSelector },
    setup() {
        const { t } = useI18n();
        const route = useRoute();

        const router = useRouter();
        const request: Ref<Request | null> = ref(null);
        const requestFiles = ref(null);

        const dateFormatter = (date: string) => formatDate(date);

        const downloading = ref(false);
        const downloadDocument = async (file: FileData) => {
            downloading.value = true;

            let response = await axios.get<Blob>(file.url, {
                withCredentials: true,
                responseType: 'blob',
            });

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.setAttribute('download', file.file_name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            downloading.value = false;
        };

        const loadingRequest = ref(true);
        onBeforeMount(async () => {
            try {
                request.value = await Request.get(route.params.request as string);
            } catch (e) {
                if (e instanceof ApiNotFoundError) {
                    router.replace({ name: 'not-found' });
                } else {
                    throw e;
                }
            } finally {
                loadingRequest.value = false;
            }
        });

        return {
            t,
            request,
            requestFiles,
            dateFormatter,
            downloadDocument,
            downloading,
            loadingRequest,
        };
    },
});


import Brand from '@/api/models/Brand';
import { computed, defineComponent, onMounted, Ref, ref, PropType } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'BrandSelector',
    props: {
        modelValue: {
            type: Object as PropType<Brand>,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const brands: Ref<Brand[]> = ref([]);
        const loadingBrands: Ref<boolean> = ref(true);

        const loadBrands = async () => {
            loadingBrands.value = true;
            brands.value = await Brand.all();
            brands.value.push(new Brand({ uuid: undefined, name: t('Other') }));
            loadingBrands.value = false;
        };

        const brandModel = computed({
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value),
        });

        onMounted(loadBrands);

        return {
            t,
            loadingBrands,
            brands,
            brandModel,
        };
    },
});

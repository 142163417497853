import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_ctx.reactiveErrors.length > 0)
    ? (_openBlock(), _createBlock(_component_q_card_section, {
        key: 0,
        class: "error-notifications"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.reactiveErrors, (error, i) => {
            return (_openBlock(), _createBlock(_component_q_banner, {
              key: i,
              rounded: "",
              dense: "",
              class: "error-notification text-white bg-red q-mb-sm"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(error), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
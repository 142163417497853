import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row justify-center q-mt-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_pagination = _resolveComponent("q-pagination")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_user_details = _resolveComponent("user-details")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_q_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_q_card_section, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_table, {
              flat: "",
              title: _ctx.t('Users'),
              rows: _ctx.users,
              columns: _ctx.columns,
              "row-key": "uuid",
              "no-data-label": _ctx.t('No data was found'),
              pagination: _ctx.pagination,
              "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => (_ctx.pagination = $event)),
              loading: _ctx.loadingUsers,
              onRequest: _ctx.loadUsers,
              grid: false,
              "binary-state-sort": "",
              "hide-bottom": ""
            }, {
              "top-right": _withCtx(() => [
                _createVNode(_component_q_toggle, {
                  class: "q-mr-lg",
                  dense: "",
                  modelValue: _ctx.showInactiveUsers,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.showInactiveUsers = $event)),
                  label: _ctx.t('Show inactive users')
                }, null, 8, ["modelValue", "label"]),
                (_ctx.isAdmin)
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 0,
                      color: "primary",
                      onClick: _ctx.openDialog
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Create user')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              body: _withCtx((props) => [
                _createVNode(_component_q_tr, {
                  onClick: ($event: any) => (_ctx.openDialog(props.row)),
                  props: props,
                  style: [{"cursor":"pointer"}, !props.row.active ? _ctx.inactiveRowStyle : {}]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_td, {
                      key: "firstname",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.firstname), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "middlename",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.middlename), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "lastname",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.lastname), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "email",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.email), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "role",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.role), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "created_at",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.dateFormatter(props.row.created_at || '')), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  _: 2
                }, 1032, ["onClick", "props", "style"])
              ]),
              _: 1
            }, 8, ["title", "rows", "columns", "no-data-label", "pagination", "loading", "onRequest"]),
            _createVNode("div", _hoisted_1, [
              _createVNode(_component_q_pagination, {
                modelValue: _ctx.pagination.page,
                "onUpdate:modelValue": [
                  _cache[3] || (_cache[3] = ($event: any) => (_ctx.pagination.page = $event)),
                  _cache[4] || (_cache[4] = ($event: any) => (_ctx.loadUsers({ pagination: _ctx.pagination })))
                ],
                color: "primary",
                max: _ctx.pagesNumber,
                "max-pages": 10,
                "boundary-numbers": "",
                size: "sm",
                "direction-links": ""
              }, null, 8, ["modelValue", "max"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.showUserDialog,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.showUserDialog = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_user_details, {
          user: _ctx.userModel,
          "onUpdate:user": _cache[5] || (_cache[5] = ($event: any) => (_ctx.userModel = $event)),
          onClose: _ctx.closeDialog
        }, null, 8, ["user", "onClose"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}